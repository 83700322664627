<template>
    <v-row>
        <v-col :cols="12">
            <planning-scheduled-reporting/>
        </v-col>
    </v-row>
</template>


<script>

import PlanningScheduledReporting from "@/components/yooda-components/reporting/PlanningScheduledReporting";

export default {
    data() {
        return {

        }
    },
    methods:{

    },
    components: {
        PlanningScheduledReporting
    },

    mounted() {
        this.analyticsIdentify()
    }
}

</script>

<style lang="scss">

</style>
