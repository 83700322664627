<template>
    <div>
        <div class="v-application">
            <h4 class="text-h4 font-weight-black primary--text mb-10">{{ $t('component_planningScheduledReporting_title') }}</h4>
        </div>

        <v-tabs v-model="selectedTab" class="custom-tabs">
            <v-tab :key="1">{{ $t('component_planningScheduledReporting_tabTitle1') }}</v-tab>
            <v-tab :key="2">{{ $t('component_planningScheduledReporting_tabTitle2') }}</v-tab>
        </v-tabs>

        <v-card style="border-top-left-radius: 0 !important" class="v-application">
            <v-card-text>
                <v-tabs-items v-model="selectedTab">
                    <v-tab-item :key="1">
                        <div>
                            <v-text-field
                                v-model="reportingSearch"
                                :label="$t('component_planningScheduledReporting_tab1_searchLabel')"
                                prepend-inner-icon="mdi-magnify"
                                style="width: 300px"
                            ></v-text-field>


                            <ag-grid-table
                                v-if="computedReportingList.data"
                                :show-floating-filter="false"
                                :table-data="{columnDefs:computedColumnDefs,rowData:filteredData.computed}"
                                agGridStyle="max-height:450px;"
                            />
                            <div class="v-application text-center" style="height: 510px" v-else>
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    style="top: 45%"
                                />
                            </div>

                        </div>
                    </v-tab-item>

                    <v-tab-item :key="2">
                        <div>
                            <v-text-field
                                v-model="reportingSearch"
                                :label="$t('component_planningScheduledReporting_tab2_searchLabel')"
                                prepend-inner-icon="mdi-magnify"
                                style="width: 300px"
                            ></v-text-field>

                            <ag-grid-table
                                v-if="scheduledReportingList.data"
                                :show-floating-filter="false"
                                :table-data="{columnDefs:scheduledColumnDefs,rowData:filteredData.scheduled}"
                                agGridStyle="max-height:450px;"
                            />
                            <div class="v-application text-center" style="height: 510px" v-else>
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    style="top: 45%"
                                ></v-progress-circular>
                            </div>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
        </v-card>
    </div>

</template>


<script>

import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable";
import apiCalls from "@/api/axios/dataReport/reporting";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data(){
        return{
            computedReportingList:null,
            scheduledReportingList:null,
            selectedTab:0,
            reportingSearch:'',
            computedColumnDefs:[
                {headerName: this.$t('component_planningScheduledReporting_computedColumnDefProject'), cellRendererFramework: 'CellRendererLink',field: 'project',comparator: cellSort.agGridTableSortCellValue },
                {headerName: this.$t('component_planningScheduledReporting_computedColumnDefModel'), field: 'model', cellRendererFramework: 'CellRendererLinkIcon', sortable: false },
                {headerName: this.$t('component_planningScheduledReporting_computedColumnDefFrequency'), cellRendererFramework: 'CellRendererLinkIcon', field: 'frequency', sortable: false },
                {headerName: this.$t('component_planningScheduledReporting_computedColumnDefDate'), field: 'date' },
                {headerName: this.$t('component_planningScheduledReporting_computedColumnDefAction'), width: 100,field: 'action', cellRendererFramework: 'CellRendererLinkIcon',cellClass:'ag-grid-cell-center', headerClass:'ag-grid-header-center', sortable: false },
            ],
            scheduledColumnDefs:[
                {headerName: this.$t('component_planningScheduledReporting_scheduledColumnDefProject'), cellRendererFramework: 'CellRendererLink', field: 'project',comparator: cellSort.agGridTableSortCellValue },
                {headerName: this.$t('component_planningScheduledReporting_scheduledColumnDefFrequency'), cellRendererFramework: 'CellRendererLinkIcon', field: 'frequency', sortable: false },
                {headerName: this.$t('component_planningScheduledReporting_scheduledColumnDefDate'), field: 'date' },
            ],
        }
    },
    computed:{

        filteredData() {
            return {
                computed : this.filterData(this.computedReportingList.data),
                scheduled : this.filterData(this.scheduledReportingList.data),
            }
        },
    },
    components:{
        AgGridTable
    },
    methods:{
        filterData(reportingData){
            if(reportingData){
                return reportingData.filter(data => {
                    if (this.reportingSearch !== '') {
                        if (data.project.value.toLowerCase().search(this.reportingSearch.toLowerCase()) >= 0 || data.frequency.label.toLowerCase().search(this.reportingSearch.toLowerCase()) >= 0 || data.date.search(this.reportingSearch.toLowerCase()) >= 0) {
                            return data
                        }
                    } else {
                        return data
                    }
                })
            }
            return []
        },
        formatDataComputed(jsonData){
            return this.formatData(jsonData,false)
        },
        formatDataScheduled(jsonData){
            return this.formatData(jsonData,true)
        },
        formatData(jsonData, scheduled=false){
            if(jsonData.content.length === 0){return []}
            let rowData = []

            jsonData.content.forEach(data => {

                    rowData.push({
                        project: {
                            value:data.project.name.toLowerCase(),
                            href:'projects/'+data.project.id+'/dashboard'
                        },
                        model:{
                            href:'projects/'+data.project.id+'/reporting',
                            iconName:'mdi-pencil',
                            iconSize:18,
                            label:(data.template.provider === 'yooda' ? this.$t(`component_planningScheduledReporting_scheduleConfigModelName_${data.template.name}`) : data.template.name)
                        },
                        frequency:{
                            href:'projects/'+data.project.id+'/reporting',
                            iconName:'mdi-pencil',
                            iconSize:18,
                            label:this.$t(`common_scheduled_period_${data.dateRangeType}`)
                        },
                        date: scheduled ? (data.nextReportingDate ? this.$options.filters.displayDateLocale(data.nextReportingDate) : '') : (data.currentReportingDate ? this.$options.filters.displayDateLocale(data.currentReportingDate) : ''),
                        action:{
                            href:data.url,
                            targetBlank: true,
                        }
                    })

            })
            return rowData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'computedReportingList',
                promise: apiCalls.readAllReporting(this.$route.params.subscriptionId),
                dataFormatter: this.formatDataComputed
            })

            this.getComponentData({
                componentName:'scheduledReportingList',
                promise: apiCalls.readAllReporting(this.$route.params.subscriptionId),
                dataFormatter: this.formatDataScheduled
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">
.custom-tabs{
    .v-slide-group{
        background-color: transparent !important;
    }
    .v-tab{
        background-color: #CFD8DC;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        &.v-tab--active{
            background-color: $primary;
            color: #fff;
        }
    }
}
</style>
